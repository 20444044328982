import {
  As,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  forwardRef,
  useColorModeValue,
} from "@chakra-ui/react";

/** A themed input component, with the correct background color */
export const Input = forwardRef<ChakraInputProps, As>((props, ref) => {
  const backgroundColor = useColorModeValue("white", "black");
  return (
    <ChakraInput
      ref={ref}
      backgroundColor={backgroundColor}
      {...props}
      width="100%"
    />
  );
});

Input.displayName = "Input";
