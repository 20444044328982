import {
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Spinner,
  Stack,
  StackProps,
  Text,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";
import * as EmailValidator from "email-validator";
import { Input } from "features/design-system/Input";
import { MessageBox } from "features/design-system/MessageBox";
import { Textarea } from "features/design-system/Textarea";
import { TextLink } from "features/design-system/TextLink";
import * as React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as api from "utils/api";
import { useAuth } from "utils/auth/auth-utils";
import { createTexts, useTranslation } from "utils/i18n";
import * as logger from "utils/logger";

type ContactFormProps = StackProps;
export const ContactForm: React.FC<ContactFormProps> = (props) => {
  const { user, isLoading } = useAuth();
  const { t } = useTranslation();

  type FormData = { email: string; text: string };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>();

  type FormState = "idle" | "loading" | "error" | "success";
  const [formState, setFormState] = useState<FormState>("idle");

  const onSubmit = async ({ email, text }: FormData) => {
    try {
      setFormState("loading");
      await api.sendContactForm({ email: user?.email || email, text });
      setFormState("success");
    } catch (e) {
      setFormState("error");
      Sentry.captureException(e);
      logger.error(e);
    }
  };

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (formState === "success") {
    return (
      <MessageBox colorScheme="green" {...props}>
        <Heading fontSize="2xl">{t(texts.messageSent.heading)}</Heading>
        <Text>{t(texts.messageSent.description)}</Text>
      </MessageBox>
    );
  }

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)} {...props}>
      {formState === "error" && (
        <MessageBox colorScheme="red">
          <Heading as="h3" fontSize="xl">
            {t(texts.messageFailed.heading)}
          </Heading>
          <Text>{t(texts.messageSent.description)}</Text>
        </MessageBox>
      )}
      {user?.email ? (
        <Text>{t(texts.email.loggedInLabel(user.email))}</Text>
      ) : (
        <FormControl isInvalid={!!errors.email}>
          <FormLabel>{t(texts.email.label)}</FormLabel>
          <Input
            type="email"
            {...register("email", {
              required: t(texts.email.validation.required),
              validate: (input) =>
                EmailValidator.validate(input) ||
                !input.endsWith(".con") ||
                t(texts.email.validation.invalid),
            })}
          />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>
      )}
      <FormControl isInvalid={!!errors.text}>
        <FormLabel>{t(texts.message.label)}</FormLabel>
        <Textarea
          {...register("text", {
            required: t(texts.message.validation.required),
          })}
          minHeight="10em"
        />
        <FormErrorMessage>{errors.text?.message}</FormErrorMessage>
        <FormHelperText>{t(texts.message.helperText)}</FormHelperText>
      </FormControl>
      <Flex justifyContent="flex-end">
        <Button
          width="fit-content"
          variant="solid"
          colorScheme="yellow"
          type="submit"
          isLoading={formState === "loading"}
          loadingText={t(texts.sendingButtonText)}
        >
          {t(texts.sendButtonText)}
        </Button>
      </Flex>
    </Stack>
  );
};

const texts = createTexts({
  message: {
    label: {
      en: "Message",
      no: "Melding",
    },
    helperText: {
      en: "Do not include sensitive information in this form.",
      no: "Ikke oppgi sensitive opplysninger i dette skjemaet.",
    },
    validation: {
      required: {
        en: "Please fill out a message",
        no: "Du må fylle ut noe i meldingsfeltet",
      },
    },
  },
  email: {
    label: {
      en: "Email",
      no: "E-post",
    },
    loggedInLabel: (email) => ({
      en: (
        <>
          You will be contacted at <strong>{email}</strong>
        </>
      ),
      no: (
        <>
          Du vil bli kontaktet på <strong>{email}</strong>
        </>
      ),
    }),
    validation: {
      required: {
        en: "Please fill out an email",
        no: "Du må fylle ut en e-post",
      },
      invalid: {
        en: "Please fill out a valid email",
        no: "Du må fylle ut en gyldig e-post",
      },
    },
  },
  sendButtonText: {
    no: "Send inn",
    en: "Send",
  },
  sendingButtonText: {
    no: "Sender inn…",
    en: "Sending…",
  },
  messageSent: {
    heading: {
      en: "Thank you for your message",
      no: "Takk for meldingen",
    },
    description: {
      en: "You will be contacted shortly, typically within a few hours.",
      no: "Du vil bli kontaktet snart, typisk i løpet av noen timer.",
    },
  },
  messageFailed: {
    heading: {
      en: "Something went wrong",
      no: "Noe gikk galt",
    },
    description: {
      no: (
        <>
          Det ser ut til at kontaktskjemaet ikke fungerte som forventet. Prøv
          igjen. Om feilen skulle vedvare, vennligst send en e-post til{" "}
          <TextLink href="mailto:team@opra.no">team@opra.no</TextLink>{" "}
          istedenfor.
        </>
      ),
      en: (
        <>
          It seems like the contact form did not work as expected. Please try
          again. If the problem persists, please send an email to{" "}
          <TextLink href="mailto:team@opra.no">team@opra.no</TextLink> instead.
        </>
      ),
    },
  },
});
