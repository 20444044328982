import { Box } from "@chakra-ui/react";
import { ContactForm } from "features/contact-form/ContactForm";

export const ContactFormSectionSerializer = () => {
  return (
    <Box marginTop={6}>
      <ContactForm />
    </Box>
  );
};
