import { PortableTextProps } from "@portabletext/react";
import { PortableText } from "../PortableText";

type TextSectionProps = {
  value: {
    content: PortableTextProps["value"];
  };
};
export const TextSection = ({ value }: TextSectionProps) => (
  <PortableText value={value.content} />
);
