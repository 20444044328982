import {
  Box,
  BoxProps,
  Flex,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

type MessageBoxProps = BoxProps & {
  /** The color way of your message box.
   *
   * Use to show warnings, successes, information and errors, respectively */
  colorScheme: "yellow" | "green" | "blue" | "red";
  /** The content of your message box */
  children: React.ReactNode;
  /** Optional icon */
  icon?: React.ReactNode;
};
/** Show messages to your users
 *
 * The "feel" of your message can be specified by the `colorScheme` prop.
 */
export const MessageBox: React.FC<MessageBoxProps> = ({
  colorScheme,
  children,
  icon,
  ...rest
}) => {
  const borderColor = useColorModeValue(
    `${colorScheme}.300`,
    `${colorScheme}.700`
  );
  const backgroundColor = useColorModeValue(
    `${colorScheme}.100`,
    `${colorScheme}.800`
  );
  return (
    <Box
      backgroundColor={backgroundColor}
      border="1px solid"
      borderColor={borderColor}
      borderRadius="md"
      boxShadow="md"
      padding={3}
      {...rest}
    >
      <Flex
        flexDirection={["column", "row"]}
        alignItems="center"
        justifyContent="center"
      >
        {icon && (
          <Box fontSize="3em" marginRight={[0, 6]} marginBottom={[3, 0]}>
            {icon}
          </Box>
        )}
        <Stack flex="1">{children}</Stack>
      </Flex>
    </Box>
  );
};
