import { Text } from "@chakra-ui/react";

type IntroSectionProps = {
  value: { content: string };
};
export const IntroSectionSerializer = (props: IntroSectionProps) => {
  return (
    <Text
      fontSize={["xl", "2xl"]}
      textAlign={["left", "center"]}
      paddingY={6}
      maxWidth="600px"
      marginX="auto"
    >
      {props.value.content}
    </Text>
  );
};
