import { Box, Image, SimpleGrid } from "@chakra-ui/react";
import { PortableTextProps } from "@portabletext/react";
import { SanityImageSource, getImageDimensions } from "@sanity/asset-utils";
import { PortableText } from "features/portable-text/PortableText";
import { urlFor } from "utils/sanity";

type ImageAndTextSectionProps = {
  value: {
    image: SanityImageSource;
    content: PortableTextProps["value"];
    imagePlacement: "left" | "right";
  };
};

export const ImageAndTextSection = ({
  value: { image, imagePlacement, content },
}: ImageAndTextSectionProps) => {
  const imageDimensions = getImageDimensions(image);
  const imageComponent = (
    <Image
      src={urlFor(image).width(1000).url()!}
      alt=""
      marginBottom={[0, 6]}
      width="100%"
      justifySelf="flex-start"
      __css={{ aspectRatio: imageDimensions.aspectRatio }}
    />
  );
  return (
    <SimpleGrid columns={[1, 2]} gap={[2, 8]} marginBottom={6}>
      {imagePlacement === "left" && imageComponent}
      <Box>
        <PortableText value={content} />
      </Box>
      {imagePlacement === "right" && imageComponent}
    </SimpleGrid>
  );
};
