import {
  Code,
  Heading,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { PortableTextComponents } from "@portabletext/react";
import { PullQuote } from "features/design-system/PullQuote";
import { TextLink } from "features/design-system/TextLink";
import * as React from "react";
import {
  EmphasisSerializer,
  ImageSerializer,
  StrikeThroughSerializer,
  StrongSerializer,
  UnderlineSerializer,
} from "./components/BlockSerializers";
import { ContactFormSectionSerializer } from "./components/ContactFormSection";
import { FeaturedListSerializer } from "./components/FeaturedList";
import { HeroSection } from "./components/HeroSection";
import { ImageAndTextSection } from "./components/ImageAndTextSection";
import { ImageWithDescriptionSerializer } from "./components/ImageWithDescription";
import { IntroSectionSerializer } from "./components/IntroSection";
import { TextSection } from "./components/TextSection";

type SerializerProps = {
  children?: React.ReactNode;
};

export const serializers: Partial<PortableTextComponents> = {
  types: {
    image: ImageSerializer,
    imageWithDescription: ImageWithDescriptionSerializer,
    introSection: IntroSectionSerializer,
    contactFormSection: ContactFormSectionSerializer,
    detailList: FeaturedListSerializer,
    imageAndTextSection: ImageAndTextSection,
    textSection: TextSection,
    heroSection: HeroSection,
  },
  block: {
    h1: ({ children }: SerializerProps) => (
      <Heading as="h1" size="xl" marginTop={0} marginBottom={3}>
        {children}
      </Heading>
    ),
    h2: ({ children }: SerializerProps) => (
      <Heading as="h2" size="lg" marginTop={8} marginBottom={3}>
        {children}
      </Heading>
    ),
    h3: ({ children }: SerializerProps) => (
      <Heading as="h3" size="md" marginTop={6} marginBottom={2}>
        {children}
      </Heading>
    ),
    h4: ({ children }: SerializerProps) => (
      <Heading
        as="h4"
        size="md"
        marginTop={4}
        marginBottom={2}
        fontWeight="normal"
      >
        {children}
      </Heading>
    ),
    h5: ({ children }: SerializerProps) => (
      <Heading as="h5" size="sm" marginTop={2} marginBottom={1}>
        {children}
      </Heading>
    ),
    h6: ({ children }: SerializerProps) => (
      <Heading as="h6" size="xs" marginTop={1} marginBottom={1}>
        {children}
      </Heading>
    ),
    blockquote: ({ children }: SerializerProps) => (
      <PullQuote>{children}</PullQuote>
    ),
    normal: ({ children }: SerializerProps) => (
      <Text marginTop={1}>{children}</Text>
    ),
  },
  marks: {
    strong: StrongSerializer,
    em: EmphasisSerializer,
    code: Code,
    underline: UnderlineSerializer,
    "strike-through": StrikeThroughSerializer,
    link: ({ value, children }) =>
      value.href ? <TextLink href={value.href}>{children}</TextLink> : null,
  },

  // Less common overrides
  list: {
    bullet: ({ children }) => (
      <UnorderedList paddingLeft={6} marginTop={3}>
        {children}
      </UnorderedList>
    ),
    number: ({ children }) => (
      <OrderedList paddingLeft={6} marginTop={3}>
        {children}
      </OrderedList>
    ),
  },
  listItem: {
    bullet: ({ children }) => <ListItem>{children}</ListItem>,
    number: ({ children }) => <ListItem>{children}</ListItem>,
  },
};
