import {
  FeaturedList,
  FeaturedListItem,
} from "features/featured-list/FeaturedList";
import React from "react";

type FeaturedListSerializerProps = {
  value: {
    items: {
      _key: string;
      title: string;
      description?: string;
    }[];
  };
};
export const FeaturedListSerializer = ({
  value,
}: FeaturedListSerializerProps) => {
  return (
    <FeaturedList>
      {value.items.map((item) => (
        <FeaturedListItem key={item._key} description={item.description}>
          {item.title}
        </FeaturedListItem>
      ))}
    </FeaturedList>
  );
};
