import { BoxProps, Text, useColorModeValue } from "@chakra-ui/react";
import * as React from "react";

type PullQuoteProps = BoxProps & {
  children: React.ReactNode;
};
export const PullQuote = (props: PullQuoteProps) => {
  const color = useColorModeValue("gray.700", "gray.300");
  return (
    <Text
      as="blockquote"
      color={color}
      paddingX={[3, 6]}
      fontSize="xl"
      lineHeight="xl"
      fontStyle="italic"
      {...props}
    />
  );
};
