import {
  PortableText as InternalPortableText,
  PortableTextProps,
} from "@portabletext/react";
import { serializers } from "./serializers";

/** Wrapper component for serializers */
export const PortableText = (
  props: Exclude<PortableTextProps, "serializers">
) => <InternalPortableText components={serializers} {...props} />;
