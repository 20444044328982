import {
  BoxProps,
  Container,
  Flex,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { urlFor } from "utils/sanity";
import { PortableText } from "../PortableText";
import { ImageWithDescriptionSerializerProps } from "./ImageWithDescription";

type HeroSectionProps = {
  value: {
    heading: string;
    content: any;
    background: BackgroundType;
  };
};

type BackgroundType =
  | {
      type: "image";
      image: ImageWithDescriptionSerializerProps["value"];
    }
  | {
      type: "color";
      lightModeColor: string;
      darkModeColor: string;
    };

export const HeroSection = ({ value }: HeroSectionProps) => {
  let backgroundProps: BoxProps = {
    backgroundPosition: ["80% 60%", "60% 80%"],
    backgroundRepeat: "no-repeat",
    backgroundSize: ["auto 140%", "cover"],
    backgroundColor: useColorModeValue(
      value.background.type === "color"
        ? value.background.lightModeColor
        : "white",
      value.background.type === "color"
        ? value.background.darkModeColor
        : "black"
    ),
  };
  if (value.background.type === "image") {
    backgroundProps.backgroundImage = `radial-gradient(circle, rgba(0,0,0,0.4), rgba(0,0,0,0.1),transparent), url(${urlFor(
      value.background.image.image
    )
      .width(1000)
      .url()!})`;
  }
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      minHeight={["100vh", "75vh"]}
      {...backgroundProps}
      color="white"
    >
      <Container maxWidth="600px" textAlign="center" paddingY={12}>
        <Stack spacing={6}>
          <PortableText value={value.content} />
        </Stack>
      </Container>
    </Flex>
  );
};
