import {
  As,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  forwardRef,
  useColorModeValue,
} from "@chakra-ui/react";

/** A themed textarea component, with the correct background color */
export const Textarea = forwardRef<ChakraTextareaProps, As>((props, ref) => {
  const backgroundColor = useColorModeValue("white", "black");
  return (
    <ChakraTextarea ref={ref} backgroundColor={backgroundColor} {...props} />
  );
});
