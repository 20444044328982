import { Box, Image } from "@chakra-ui/react";
import { SanityImageSource } from "@sanity/asset-utils";
import { urlFor } from "utils/sanity";
import { SerializerProps } from "./SerializerProps";

export const StrongSerializer = ({ children }: SerializerProps) => {
  return <strong>{children}</strong>;
};
export const EmphasisSerializer = ({ children }: SerializerProps) => {
  return <em>{children}</em>;
};
export const UnderlineSerializer = ({ children }: SerializerProps) => {
  return (
    <Box
      as="u"
      display="inline"
      borderBottom="1px solid currentColor"
      textDecoration="none"
    >
      {children}
    </Box>
  );
};
export const StrikeThroughSerializer = ({ children }: SerializerProps) => {
  return (
    <Box as="s" textDecoration="line-through">
      {children}
    </Box>
  );
};
type ImageSerializerProps = SerializerProps & {
  value: SanityImageSource;
};
export const ImageSerializer = ({ value }: ImageSerializerProps) => {
  return <Image src={urlFor(value).width(1000).url()!} alt="" />;
};
