import { Box, Image, Text } from "@chakra-ui/react";
import { SanityImageSource } from "@sanity/asset-utils";
import { urlFor } from "utils/sanity";
import { SerializerProps } from "./SerializerProps";

export type ImageWithDescriptionSerializerProps = SerializerProps & {
  value: {
    image: SanityImageSource;
    alt: string;
    description?: string;
  };
};
export const ImageWithDescriptionSerializer = ({
  value,
}: ImageWithDescriptionSerializerProps) => {
  return (
    <Box as="figure">
      <Image src={urlFor(value.image).width(1000).url()!} alt={value.alt} />
      {value.description && (
        <Text textAlign="center" color="grey.500">
          {value.description}
        </Text>
      )}
    </Box>
  );
};
